<script>
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import { format as formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { getContext } from "svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpBulkReceive from "~/components/help/HelpBulkReceive.svelte";
  import RoleIcon from "~/components/RoleIcon.svelte";
  import backendApi, { OfflineException } from "~/libs/backendApi";
  import companies from "~/libs/companies";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    QrHomeTypes,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import geolocator from "~/libs/geolocator";
  import inTransitDeliveryListUtils from "~/libs/inTransitDeliveryListUtils";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import pageRouter from "~/libs/pageRouter";
  import { updateCenter } from "~/libs/stores";
  import { reserveUpdateDeliveryRecordsAndSyncBackend } from "~/libs/syncOperationState";
  import { toast } from "~/libs/toast";
  import { formatStringDate } from "~/libs/utils";

  const FIXED_CUBIC_SIZE = 100;

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {number} 一括荷受けを行う対象の荷受センターID */
  const centerId = $updateCenter ? Number($updateCenter.split("/")[0]) : null;

  /** @type {string} 一括荷受けを行う対象の荷受センター名 */
  const centerName = $updateCenter ? $updateCenter.split("/")[1] : null;

  /** @type {Array<import("~/libs/backendApi").LineHaulRoute>} 所属する企業が担当する幹線輸送ルートの一覧 */
  let lineHaulRouteList = [];

  /** @type {boolean} */
  let showAllLineHaulRoute = false;

  /**
   * @typedef {{
   *   companyName:string,
   *   lineHaulRouteId: number,
   *   lineHaulRouteName: string,
   *   order: number,
   *   hidden: boolean,
   *   shippingReceiptUnit: {
   *     shippingReceiptUnitId: number,
   *     companyId: number,
   *     receiptLocationId: number,
   *     toReceiveOn: string,
   *     sequentialNumber: number,
   *     createdAt: string,
   *     numberOfPackages: number,
   *     numberOfPackagesByLocation: {locationId: number, numberOfPackages: number}[],
   *   },
   *   selected: boolean
   * }} DisplayShippingReceiptUnit 画面表示用荷受け情報
   */

  /** @type {Array<DisplayShippingReceiptUnit>} 画面表示用荷受け情報リスト */
  let displayShippingReceiptUnitList = [];

  /**
   * 幹線輸送ルート別の選択中荷受け情報のリスト
   * @type {Array<{
   *   lineHaulRouteId: number,
   *   lineHaulRouteName: string,
   *   selectedShippingReceiptUnitName: string,
   *   selectedShippingReceiptUnitList: Array<{
   *     locationId: number,
   *     numberOfPackages: number
   *   }>,
   *   numberOfPackagesSummary: number
   * }>}
   */
  let selectedShippingReceiptUnitListByRoute = [];

  /** @type {ConfirmDialog} 一括荷受け登録確認ダイアログ */
  let bulkReceiveConfirmDialog;

  /** 配送センターIDをキーとしたセンター情報のMap @type {Map<number, import("~/libs/commonTypes").DepotLocation>} */
  let centersMap;

  /** @type {import("svelte").ComponentType<HelpBase>} */
  let helpBase;

  /** @type {import("svelte").ComponentType<HelpBulkReceive>} */
  let helpContents;

  /** @type {boolean} 確定ボタンの無効フラグ（荷受け単位が1つも選択されていない場合にtrue） */
  $: disableConfirmButton = displayShippingReceiptUnitList.every(
    (displayShippingReceiptUnit) => !displayShippingReceiptUnit.selected,
  );

  // ページの初期化処理
  loadingProgress.wrapAsync(async () => {
    try {
      // 所属する企業が担当する幹線輸送ルート情報の一覧を取得
      lineHaulRouteList = await backendApi.getLineHaulList(centerId);

      // 一括荷受け情報を取得
      const shippingReceiptUnitList =
        await backendApi.getShippingReceiptUnitList();

      if (shippingReceiptUnitList === undefined) {
        toast.info($_("message.noShippingReceiptUnit"));
        pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
        return;
      }

      const filteredShippingReceiptUnitList = shippingReceiptUnitList
        .filter((item) => {
          return (
            item.receiptLocationId === centerId && item.numberOfShipments > 0
          );
        })
        .sort((a, b) => {
          let compareResult = a.companyId - b.companyId;
          if (compareResult !== 0) {
            // 会社IDが異なる場合は会社IDでソート
            return compareResult;
          }
          compareResult = a.toReceiveOn.localeCompare(b.toReceiveOn);
          if (compareResult !== 0) {
            // 荷受け日が異なる場合は荷受け日でソート
            return compareResult;
          }
          // それ以外は登録回でソート
          return a.sequentialNumber - b.sequentialNumber;
        });

      if (filteredShippingReceiptUnitList.length === 0) {
        toast.info($_("message.noShippingReceiptUnit"));
        pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
        return;
      }

      // 会社情報を取得
      let companiesList = [];
      try {
        companiesList = await companies.get();
      } catch (error) {
        if (error instanceof OfflineException) {
          toast.error($_("errors.offline"));
        } else {
          logger.error(
            "[QrHome] 会社情報の取得に失敗しました",
            {
              username: userContext.loginUser?.username,
            },
            error,
          );
          toast.error($_("errors.defaultMessage"));
        }
        // エラーログの出力とToastの表示だけ行って処理は続行
      }

      // 画面表示用荷受け情報リストを作成
      for await (const shippingReceiptUnit of filteredShippingReceiptUnitList) {
        const company = companiesList?.find(
          (c) => c.id === shippingReceiptUnit.companyId,
        );
        for (const lineHaulRoute of lineHaulRouteList) {
          // まだ荷受けされていない、かつ幹線輸送ルートの対象の荷物のみを抽出
          const targetPackages =
            shippingReceiptUnit.numberOfPackagesByLocation.filter(
              (e) =>
                !e.received &&
                lineHaulRoute.relayLocationIds.includes(e.locationId),
            );
          if (targetPackages.length === 0) continue;
          let numberOfPackages = 0;
          for (const item of targetPackages) {
            numberOfPackages += item.numberOfPackages;
          }
          if (numberOfPackages === 0) continue;
          displayShippingReceiptUnitList.push({
            companyName: company ? company.name : "取得エラー",
            lineHaulRouteId: lineHaulRoute.lineHaulRouteId,
            lineHaulRouteName: lineHaulRoute.lineHaulRouteName,
            order: lineHaulRoute.order,
            hidden: lineHaulRoute.hidden,
            shippingReceiptUnit: {
              shippingReceiptUnitId: shippingReceiptUnit.shippingReceiptUnitId,
              companyId: shippingReceiptUnit.companyId,
              receiptLocationId: shippingReceiptUnit.receiptLocationId,
              toReceiveOn: shippingReceiptUnit.toReceiveOn,
              sequentialNumber: shippingReceiptUnit.sequentialNumber,
              createdAt: shippingReceiptUnit.createdAt,
              numberOfPackages: numberOfPackages,
              numberOfPackagesByLocation: targetPackages,
            },
            selected: !lineHaulRoute.hidden,
          });
        }
      }
      // 幹線輸送ルートのプライオリティで改めてソート
      displayShippingReceiptUnitList.sort((a, b) => {
        return a.order - b.order;
      });

      // 初めての画面表示時にヘルプを表示
      if (!appContext.firstBulkReceiveOpened) {
        helpContents = HelpBulkReceive;
        helpBase = HelpBase;
        appContext.firstBulkReceiveOpened = true;
        appContext.store();
      }

      displayShippingReceiptUnitList = displayShippingReceiptUnitList;
    } catch (error) {
      logger.error(
        "[BulkReceive] 荷受け一覧の取得に失敗",
        {
          username: userContext.loginUser?.username,
        },
        error,
      );
      if (error instanceof OfflineException) {
        toast.recommendOfflineMode($_("errors.offline"));
      } else {
        toast.error($_("errors.defaultMessage"));
      }
      pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
    }
  })();

  // ページの初期化処理（非同期）
  (async () => {
    try {
      centersMap = await depotLocations.getCentersMap();
    } catch (error) {
      if (error instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else {
        logger.error(
          "[QrHome] 配送センター情報の取得に失敗しました",
          {
            username: userContext.loginUser?.username,
          },
          error,
        );
        toast.error($_("errors.defaultMessage"));
      }
      // エラーログの出力とToastの表示だけ行って処理は続行
    }
  })();

  /**
   * 前処理をしてから一括荷受け登録確認ダイアログを開く
   */
  function openBulkReceiveConfirmDialog() {
    // 幹線輸送ルート毎に表示情報を初期化
    selectedShippingReceiptUnitListByRoute = [];
    for (const lineHaulRoute of lineHaulRouteList) {
      selectedShippingReceiptUnitListByRoute.push({
        lineHaulRouteId: lineHaulRoute.lineHaulRouteId,
        lineHaulRouteName: lineHaulRoute.lineHaulRouteName,
        selectedShippingReceiptUnitName: "",
        selectedShippingReceiptUnitList: [],
        numberOfPackagesSummary: 0,
      });
    }

    for (const displayShippingReceiptUnit of displayShippingReceiptUnitList) {
      if (displayShippingReceiptUnit.selected) {
        // 選択中の荷受け単位の場合、幹線輸送ルート別の選択中荷受け情報のリストに追加
        const indexOfRoute = selectedShippingReceiptUnitListByRoute.findIndex(
          (item) =>
            item.lineHaulRouteId === displayShippingReceiptUnit.lineHaulRouteId,
        );
        if (indexOfRoute >= 0) {
          // 表示名称を作成
          selectedShippingReceiptUnitListByRoute[
            indexOfRoute
          ].selectedShippingReceiptUnitName +=
            `【${displayShippingReceiptUnit.companyName}】${formatStringDate(
              displayShippingReceiptUnit.shippingReceiptUnit.toReceiveOn,
              "M/d(E)",
              {
                locale: localeJa,
              },
            )}${displayShippingReceiptUnit.shippingReceiptUnit.sequentialNumber.toLocaleString()}回目<br />`;

          // 輸送先毎に荷物数を集計
          for (const numberOfPackagesByLocation of displayShippingReceiptUnit
            .shippingReceiptUnit.numberOfPackagesByLocation) {
            const indexOfShippingReceiptUnit =
              selectedShippingReceiptUnitListByRoute[
                indexOfRoute
              ].selectedShippingReceiptUnitList.findIndex(
                (item) =>
                  item.locationId === numberOfPackagesByLocation.locationId,
              );
            if (indexOfShippingReceiptUnit >= 0) {
              selectedShippingReceiptUnitListByRoute[
                indexOfRoute
              ].selectedShippingReceiptUnitList[
                indexOfShippingReceiptUnit
              ].numberOfPackages += numberOfPackagesByLocation.numberOfPackages;
            } else {
              selectedShippingReceiptUnitListByRoute[
                indexOfRoute
              ].selectedShippingReceiptUnitList.push({
                locationId: numberOfPackagesByLocation.locationId,
                numberOfPackages: numberOfPackagesByLocation.numberOfPackages,
              });
            }
            // 合計の荷物数を集計
            selectedShippingReceiptUnitListByRoute[
              indexOfRoute
            ].numberOfPackagesSummary +=
              numberOfPackagesByLocation.numberOfPackages;
          }
        }
      }
    }

    bulkReceiveConfirmDialog.openDialog();
  }

  const updateStatuses = loadingProgress.wrapAsync(async () => {
    /** 登録成功があった場合にtrue */
    let hasSuccess = false;

    // 現在時刻を保持して現在位置の取得をリクエスト
    const requestedTimeStamp = Date.now();
    geolocator.requestCurrentPosition(false, requestedTimeStamp);

    for await (const displayShippingReceiptUnit of displayShippingReceiptUnitList) {
      if (displayShippingReceiptUnit.selected) {
        // 荷受け登録する荷物の宛先中継配送センターIDをリスト化
        let locationIdList = [];
        for (const item of displayShippingReceiptUnit.shippingReceiptUnit
          .numberOfPackagesByLocation) {
          locationIdList.push(item.locationId);
        }
        // 荷受け情報を登録
        try {
          const registeredInfoList =
            await backendApi.registReceivedShippingReceiptUnit(
              {
                relayLocationIds: locationIdList,
                receiptAt: formatDate(new Date(), "yyyy-MM-dd HH:mm:ss"),
                receiptLocationId: centerId,
                cubicSize: FIXED_CUBIC_SIZE,
              },
              displayShippingReceiptUnit.shippingReceiptUnit
                .shippingReceiptUnitId,
            );

          if (registeredInfoList?.length) {
            // 荷受け完了した荷物を輸送中リストに追加する
            let inTransitDeliveryList = userContext.inTransitDeliveryList ?? [];

            for (const item of registeredInfoList) {
              const transportDestinationId = item.locationId;
              const trackingNumberAndQuantityList = [];
              for (const trackingNumberAndQuantity of item.trackingNumbers) {
                trackingNumberAndQuantityList.push({
                  trackingNumber: trackingNumberAndQuantity.trackingNumber,
                  quantity: trackingNumberAndQuantity.numberOfPackages,
                });
              }

              inTransitDeliveryListUtils.add(
                inTransitDeliveryList,
                centerId,
                transportDestinationId,
                trackingNumberAndQuantityList,
              );
            }

            userContext.inTransitDeliveryList = inTransitDeliveryList;
            userContext.store();

            hasSuccess = true;
          }
        } catch (error) {
          // エラーログとメッセージを出力して継続
          logger.error(
            "[BulkReceive] 一括荷受けでの荷受け登録に失敗",
            {
              username: userContext.loginUser?.username,
              shippingReceiptUnitId:
                displayShippingReceiptUnit.shippingReceiptUnit
                  .shippingReceiptUnitId,
            },
            error,
          );
          toast.error(
            $_("errors.failedToBulkReceive", {
              values: {
                shippingReceiptUnit: `【${displayShippingReceiptUnit.companyName}】${formatStringDate(
                  displayShippingReceiptUnit.shippingReceiptUnit.toReceiveOn,
                  "M/d(E)",
                  {
                    locale: localeJa,
                  },
                )}${displayShippingReceiptUnit.shippingReceiptUnit.sequentialNumber.toLocaleString()}回目`,
              },
            }),
          );
        }
      }
    }

    if (hasSuccess) {
      // 登録成功したデータが有った場合
      // ドライバーの稼働状況をシステムに同期
      reserveUpdateDeliveryRecordsAndSyncBackend(
        userContext,
        requestedTimeStamp,
      );
      // 一括荷受け完了メッセージを表示
      pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
      toast.info($_("message.updateCompleteBulkReceive"));
    }
  });

  function goToBackPage() {
    pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
  }

  /**
   * ヘルプを閉じる
   */
  function clickConfirm() {
    helpBase = null;
    helpContents = null;
  }
</script>

<!-- ヘルプ表示 -->
<svelte:component this={helpBase} {helpContents} {clickConfirm} />

<div class="mainContentsWrapper">
  <Header>
    <svelte:fragment slot="left">
      {#if userContext.canSwitchRole()}
        <RoleIcon />
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="center">一括荷受け</svelte:fragment>
  </Header>

  <main in:fade>
    <p class="description">
      {centerName}で一括荷受けを行う対象を選択してください。
    </p>
    <div class="border" />
    <div class="selectShowAllRouteArea">
      <FormField>
        <Checkbox bind:checked={showAllLineHaulRoute} />
        <span slot="label">全ての幹線輸送ルートを表示する</span>
      </FormField>
    </div>
    <div class="selectArea">
      {#each displayShippingReceiptUnitList as displayShippingReceiptUnit}
        {#if !displayShippingReceiptUnit.hidden || showAllLineHaulRoute}
          <FormField>
            <Checkbox bind:checked={displayShippingReceiptUnit.selected} />
            <div slot="label" class="receivingInfo">
              <p class="receivingId">
                【{displayShippingReceiptUnit.companyName}】{formatStringDate(
                  displayShippingReceiptUnit.shippingReceiptUnit.toReceiveOn,
                  "M/d(E)",
                  {
                    locale: localeJa,
                  },
                )}
                {displayShippingReceiptUnit.shippingReceiptUnit.sequentialNumber.toLocaleString()}回目
              </p>
              <table class="receivingTable">
                <tr>
                  <th colspan="2" class="destinationColumn"
                    >{displayShippingReceiptUnit.lineHaulRouteName} (計{displayShippingReceiptUnit.shippingReceiptUnit.numberOfPackages.toLocaleString()}個)</th
                  >
                </tr>
                {#each displayShippingReceiptUnit.shippingReceiptUnit.numberOfPackagesByLocation as numberOfPackagesByLocation}
                  <tr>
                    <th
                      >{centersMap?.get(numberOfPackagesByLocation.locationId)
                        .name ?? "取得エラー"} 宛</th
                    >
                    <td
                      >{numberOfPackagesByLocation.numberOfPackages.toLocaleString()}個</td
                    >
                  </tr>
                {/each}
                <tr>
                  <th>計</th>
                  <td
                    >{displayShippingReceiptUnit.shippingReceiptUnit.numberOfPackages.toLocaleString()}個</td
                  >
                </tr>
              </table>
            </div>
          </FormField>
        {/if}
      {/each}
    </div>

    <div class="buttonArea">
      <button class="backBtn" on:click={goToBackPage}>戻る</button>
      <button
        class={disableConfirmButton ? "disabledBtn" : "confirmBtn"}
        disabled={disableConfirmButton}
        on:click={openBulkReceiveConfirmDialog}
      >
        確定
      </button>
    </div>
  </main>

  <Footer />
</div>

<div class="subContentsWrapper">
  <!-- 荷下ろし登録確認ダイアログ -->
  <ConfirmDialog
    bind:this={bulkReceiveConfirmDialog}
    mandatory={true}
    type={ConfirmDialogTypes.OK_CLOSE}
    onDialogClosedHandler={async (event) => {
      if (event.detail.action === "ok") {
        updateStatuses();
      }
    }}
  >
    <svelte:fragment slot="title">確認</svelte:fragment>
    <svelte:fragment slot="content">
      <div class="dialogContent">
        {#each selectedShippingReceiptUnitListByRoute as item}
          {#if item.selectedShippingReceiptUnitList.length > 0}
            <section>
              <h3>{item.lineHaulRouteName}</h3>
              <p>
                <!-- 内部で生成する文字列のためエスケープ不要 -->
                {@html item.selectedShippingReceiptUnitName}の一括荷受けを行います。
              </p>
              <table class="receivingTable">
                <tr>
                  <th colspan="2" class="destinationColumn"
                    >{item.lineHaulRouteName} (計{item.numberOfPackagesSummary.toLocaleString()}個)</th
                  >
                </tr>
                {#each item.selectedShippingReceiptUnitList as numberOfPackagesByLocation}
                  <tr>
                    <th
                      >{centersMap?.get(numberOfPackagesByLocation.locationId)
                        .name ?? "取得エラー"} 宛</th
                    >
                    <td
                      >{numberOfPackagesByLocation.numberOfPackages.toLocaleString()}個</td
                    >
                  </tr>
                {/each}
                <tr>
                  <th>計</th>
                  <td>{item.numberOfPackagesSummary.toLocaleString()}個</td>
                </tr>
              </table>
            </section>
          {/if}
        {/each}
      </div>
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  /* ------------
  コンテンツ部分
 --------------- */
  main {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    line-height: 1.2;
    padding-bottom: 90px;
  }

  .border {
    border-bottom: 1px solid #ccc;
    width: calc(100% - 30px);
    margin: 10px 0 2px;
  }

  .selectShowAllRouteArea {
    padding: 0 15px;
    text-align: right;
    width: calc(100% - 30px);
  }

  .receivingTable {
    min-width: 240px;
    width: 100%;
    font-size: 14px;
    margin: auto;
    text-align: left;

    th,
    td {
      border: 1px solid #00000030;
      font-weight: normal;
      padding: 2px 8px;
    }
    td {
      text-align: right;
      width: 50px;
    }
    .destinationColumn {
      background-color: #f5f5f5;
      padding: 0 8px;
    }
  }

  .description {
    padding: 0 16px;
    margin: 10px auto 0;
    line-height: 1.5;
  }

  .selectArea {
    width: 100%;
    text-align: center;

    :global(.mdc-form-field) {
      width: calc(100% - 30px);
      box-sizing: border-box;
      margin: 5px auto 0;
      padding: 14px 6px;
      text-align: left;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    :global(.mdc-form-field > label) {
      width: 100%;
    }

    :global(.mdc-form-field > label) {
      margin-right: 0;
      width: 100%;
    }

    .receivingInfo {
      border-left: 1px solid #00000030;
      padding-left: 10px;
    }

    .receivingId {
      font-size: 15px;
      margin-bottom: 6px;
    }
  }

  /* ボタンエリア */
  .buttonArea button {
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    font-weight: bold;
    color: #fff;
  }

  .backBtn {
    position: fixed;
    font-size: 16px;
    bottom: calc(70px + var(--app-inset-bottom-padding));
    left: 25px;
    padding: 0;
    margin: 0;
    background-color: #018786;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .confirmBtn {
    position: fixed;
    font-size: 16px;
    bottom: calc(70px + var(--app-inset-bottom-padding));
    right: 25px;
    padding: 0;
    margin: 0;
    background-color: #018786;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .disabledBtn {
    position: fixed;
    font-size: 16px;
    bottom: calc(70px + var(--app-inset-bottom-padding));
    right: 25px;
    padding: 0;
    margin: 0;
    background-color: #ccc;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .confirmBtn,
  .disabledBtn {
    :global(.smui-badge.smui-badge--color-primary) {
      background-color: red;
    }
  }

  .subContentsWrapper {
    .dialogContent {
      width: 100%;
      color: #333;

      section:not(:first-of-type) {
        margin-top: 20px;
      }

      p {
        font-size: 16px;
      }
    }
  }
</style>
